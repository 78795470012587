export const main = {
  onboardingTitle: 'Lösen Sie Ihr Falschparker Problem!',
  onboardingDescription:
    'Über Falsch-Parker stellen Sie Umtriebsentschädigungen aus und schleppen gesetzeskonform ab.',
  onboardingButton: 'Jetzt starten',
  signinTitle: 'Anmelden',
  requiredError: 'Dieses Feld ist erforderlich',
  emailLabel: 'E-Mail',
  emailPlaceholder: 'E-Mail eingeben',
  emailValidationError: 'Ihre E-Mail ist falsch',
  firstNameLabel: 'Vorname',
  firstNamePlaceholder: 'Vorname eingeben',
  lastNameLabel: 'Nachname',
  lastNamePlaceholder: 'Nachname eingeben',
  phoneLabel: 'Telefon Nummer',
  phonePlaceholder: 'Trage deine Telefonnummer ein',
  phoneValidationError: 'Telefonnummer ist zu kurz',
  password: 'Passwort',
  passwordPlaceholder: 'Geben Sie Ihr Passwort ein',
  passwordValidationError: 'Passwort ist zu kurz',
  confirmPassword: 'Bestätigen Sie das Passwort',
  confirmPasswordPlaceholder: 'Bestätigen Sie das Passwort',
  confirmPasswordValidationError: 'Passwörter stimmen nicht überein',
  confirmNewPasswordValidationError: 'Neue Kennwörter stimmen nicht überein',
  currentPasswordValidationError: 'Aktuelles Passwort ist falsch',
  signinRememberMe: 'Erinnern Sie sich an mich',
  signinForgotPassword: 'Passwort vergessen?',
  signin: 'Anmelden',
  socialMediaTitle: 'Oder weiter mit',
  signUpLabel: 'Sie haben kein Konto?',
  signUp: 'Registrieren',
  signUpTermAndConditions: 'ABG`s',
  signUpButton: 'Konto erstellen',
  signInLabel: 'Hast du bereits ein Konto?',
  referCodeTitle: 'Empfehlungscode',
  referCodePlaceholder: 'Falls kein Code, freilassen',
  doesntHaveCode: 'Haben Sie bereits ein Konto?',
  skip: 'Überspringen',
  referCodeConfirm: 'Code bestätigen',
  otpTitle: 'OTP eingeben',
  notReceivedCode: 'Keinen Code erhalten? ',
  resend: 'Erneut senden',
  otpConfirm: 'Bestätigen Sie',
  continue: 'Weiter',
  congratulations: 'Glückwunsch!',
  accountCreatedSuccesfully: 'Ihr Konto wurde erfolgreich erstellt',
  homeTitle: 'Unsere Services',
  carTowing: 'Abschleppen',
  carTowingSubtitle: 'Rechtskonform abschleppen',
  parkingTicket: 'Parkbusse',
  parkingTicketSubtitle: 'Unerlaubtes Parkieren büssen',
  companyName: 'Firmenname',
  companyNamePlaceholder: 'Firmenname eingeben',
  companyStreet: 'Firmen Strasse',
  companyStreetPlaceholder: 'Firmen Strasse einegen',
  companyStreetNumber: 'Firmen Hausnummer',
  companyStreetNumberPlaceholder: 'Firmen Hausnummer einegen',
  companyZipCode: 'Firmen Postleitzahl',
  companyZipCodePlaceholder: 'Firmen Postleitzahl einegen',
  companyCity: 'Firmen Stadt',
  companyCityPlaceholder: 'Firmen Stadt eingeben',
  companyAddress: 'Adresse',
  companyAddressPlaceholder: 'Adresse eingeben',
  userRoles: 'Benutzerrollen',
  switchToUserProfile: 'Wechsel User Profil',
  preference: 'Einstellungen',
  darkMode: 'Dark Mode',
  general: 'Parkraumverwaltung',
  rewards: 'Belohnungen',
  referAFriend: 'Mitarbeiter hinzufügen',
  becomeADriver: 'Fahrer werden',
  defaultPayment: 'Standart Zahlung',
  addNewLicenses: 'Parkerlaubnis erteilen/löschen',
  addNewProperty: 'Verwalte deine Parkplätze',
  addPropertyButton: 'Parkplätze hinzufügen',
  licenseTitle: 'Kontrollschilder verwalten',
  parkingNotExist: 'Sie müssen zuerst Parkplätze hinzufügen',
  contact: 'Allgemein',
  FAQ: 'FAQ',
  aboutUs: 'Über uns',
  helpSupport: 'Hilfe & Support',
  termsCondition: 'AGB`s',
  dataProtection: 'Datenschutzerklärung',
  dataProtectionTowShare: 'TowShare Group GmbH Datenschutzerklärung',
  imprint: 'Impressum',
  logout: 'Logout',
  editProfile: 'Hauptmenü',
  done: 'Speichern',
  changeProfilePhoto: 'Profilfoto ändern',
  referalCode: 'Empfehlungscode',
  redeemDetails: 'Details einlösen',
  add: 'Hinzufügen',
  bankTransfer: 'Banküberweisung',
  newBankDetails: 'Neue Bankverbindung',
  paypal: 'PayPal',
  newPaypalDetails: 'New PayPal Details',
  bankDetails: 'Bankverbindung',
  save: 'Speichern',
  beneficaryName: 'Besitzer ',
  beneficaryNamePlaceholder: 'Besitzer eingeben',
  bankName: 'Name der Bank',
  bankNamePlaceholder: 'Bankname eingeben',
  accountNumber: 'Kontonummer',
  accountNumberPlaceholder: 'Kontonummer eingeben',
  iban: 'IBAN',
  ibanPlaceholder: 'IBAN eingeben',
  street: 'Strasse',
  streetPlaceholder: 'Strasse eingeben',
  streetNumber: 'Hausnummer',
  streetNumberPlaceholder: 'Hausnummer eingeben',
  zipCode: 'Postleitzahl',
  zipCodePlaceholder: 'PLZ eingeben',
  city: 'Stadt',
  cityPlaceholder: 'Stadt eingeben',
  successRedeemSubtitle: 'Sie haben ein Bankkonto hinzugefügt',
  successContractSubtitle: 'Ihr Parkobjekt wurde hinzugefügt',
  rewardsHistory: 'Belohnungen Geschichte',
  history: 'History',
  sortBy: 'Sortieren nach',
  dateOfBirth: 'Geburtsdatum',
  dateOfBirthPlaceholder: 'Geben Sie Ihr Geburtsdatum ein',
  license: 'Kontrollschild',
  govermentId: 'ID / Reisepass',
  requestForVerification: 'Anfrage Verifizierung',
  verificationRequested: 'Verifizierung angefordert',
  successDriverSubtitle:
    'Wir haben Ihre Anfrage erhalten, ein Falschparker-Fahrer zu werden. Ihr Bewerbungsstatus wird Ihnen innerhalb von 1-2 Werktagen mitgeteilt.',
  orders: 'Abschleppaufträge',
  badParkedCar: 'Abschleppen',
  badParkedCarConfirmationPickup: 'Auto Abschleppen',
  badParkedCarTicketConfirmationPickup: 'Parkbusse',
  licensePlate: 'Kontrollschild',
  licensePlatePlaceholder: 'Kontrollschild eingeben',
  carModelName: 'Name des Automodells',
  carModelNamePlaceholder: 'Gib den Name des Automodells ein',
  carPhoto: 'Foto Falschparker',
  addPhoto: 'Foto hinzufügen',
  addVideo: 'Video hinzufügen',
  yourLocation: 'Standort Falschparker',
  findDriver: 'Abschlepper finden',
  changeLocation: 'Adresse ändern',
  cancel: 'Stornieren',
  cancelSecond: 'Stornieren',
  findingTheDriver: 'Abschlepper finden..',
  cancelFindDriverAlert: 'Möchtest du den Auftrag wirklich stornieren?',
  cancelOrderAlert: 'Möchtest du die Bestellung wirklich stornieren?',
  confirmOrderAlert:
    'Bist du sicher, dass alle eingegebenen Daten korrekt sind?',
  reward: 'Belohnung',
  pickUpLocation: 'Abholort',
  ETA: 'Geschätzte Ankunftzeit',
  towingDriverName: 'Abschleppfahrer Name',
  addressNotFound:
    'Die Adresse wurde nicht gefunden. Bitte versuchen Sie es erneut',
  successOrderSubtitle: 'Dein Abschleppauftrag wurde erfolgreich aufgegeben.',
  loginError: 'Falsche E-Mail oder Passwort',
  standardPayment: 'Standard Zahlung',
  addParking: 'Grundstück hinzufügen',
  unsuccessReportTitle: 'Lieber Kunde',
  unsuccessReportSubtitle:
    'Bitte fügen Sie Ihr Parkobjekt hinzu, um den Abschleppbericht oder Ticketbericht zu starten',
  oldPassword: 'Altes Passwort',
  newPassword: 'Neues Passwort',
  changePassword: 'Passwort ändern',
  confirmChangeSave: 'Die Änderungen wurden gespeichert',
  orderDetails: 'Details zum Auftrag',
  carModel: 'Auto-Modell',
  dateAndTime: 'Datum und Uhrzeit',
  driveInformation: 'Fahrer Information',
  customerInformation: 'User Information',
  ticket: 'Ticket',
  orderID: 'Auftrags Status',
  companyExecutive: 'Abschleppfahrer Name',
  confirmTicket: 'Die Umtriebsentschädigung wurde erfolgreich ausgestellt!',
  confirmTowingStatus: ' Wir schleppen den Falschparker ab!',
  all: 'Alle',
  new: 'Neu',
  accepted: 'Angenommen',
  inProgress: 'In Arbeit',
  towedAway: 'Abgeschleppt',
  finished: 'Fertig',
  finish: 'Fertig',
  cancelled: 'Storniert',
  ausgestellt: 'Ausgestellt',
  userNoReportsYet: 'Es wurden noch keine Berichte von Ihnen erstellt',
  driverNoReportsYet: 'Ihnen ist noch kein Auftrag zugewiesen',
  propertyAndLicenses: 'Hauptmenü',
  addProperty: 'Parkplatz / Grundstück hinzufügen',
  newPropertyDetails: 'Neuen Parkplatz ',
  addPropertyDetails: 'Liegenschaft / Parkplatz hinzufügen',
  addLicenses: 'Berechtigte Autos hinzufügen',
  newLicenseDetails: 'Neues Nummernschild erfassen',
  addLicenseDetails: 'Kontrollschild hinzufügen',
  carModelPlaceholder: 'Automodell eingeben',
  propertyName: 'Name Grundstück / Parkplatz (Nr)',
  propertyNamePlaceholder: 'Name hinzufügen',
  address: 'Address',
  addressPlaceholder: 'Adresse eingeben',
  signAContract: 'Vertrag unterzeichnen',
  contract: 'Vertrag',
  contractName: 'Auftrag',
  from: 'vom',
  inBetween: 'zwischen',
  hereinafter: 'nachstehend',
  client: 'Auftraggeber',
  contractor: 'Auftragnehmer',
  contractorAddress: 'TowShare Group GmbH\nSchützengasse 1\n8001 Zürich',
  contractorShortAddress: 'TowShare Group GmbH',
  and: 'und',
  contractSubtitle:
    'betreffend die Durchführung von Abschleppdienstleistung für rechtswidrig abgestellte Fahrzeuge auf Privatparkplätzen, Privatgelände und in der Tiefgarage des Auftraggebers sowie der Erhebung von Umtriebsentschädigungen bei Verstössen gegen Park- und Tankfluchtregelungen. ',
  preamble: 'Präambel ',
  constractFirstParagraphFirstPart:
    'Der Auftraggeber (Mieter oder Eigentümer eines Parkplatzes) möchte die Dienstleistungen des Auftragnehmers nutzen. Der Auftragnehmer, eine GmbH mit Sitz in Zürich, bietet IT-Lösungen für das Management von Privatparkplätzen und die Behandlung von Tankfluchtverstössen an.',
  constractFirstParagraphSecondPart:
    'Diese Vereinbarung beauftragt den Auftragnehmer, unrechtmässig abgestellte Fahrzeuge abzuschleppen und Umtriebsentschädigungen geltend zu machen.',
  contractFirstPointTitle: 'Vertragsgegenstand',
  contractFirstPointContent:
    'Der Auftragnehmer verpflichtet sich, folgende Leistungen zu erbringen:',
  contractSecondPointTitle: 'Umtriebsentschädigung',
  contractSecontPointSubcontentOne:
    'Entfernen unrechtmässig abgestellter Fahrzeuge.',
  contractSecontPointSubcontentTwo:
    'Ausstellung von Umtriebsentschädigungen bei Parkverstössen und Tankflucht.',
  contractThirdPointTitle: 'Leistungen des Auftragnehmers',
  contractThirdPointSubcontentOne:
    'Abschleppen unrechtmässig abgestellter Fahrzeuge von Privatparkplätzen, Privatgeländen und Tiefgaragen.',
  contractThirdPointSubcontentTwo:
    'Sicherer Transport und ordnungsgemässe Lagerung der abgeschleppten Fahrzeugen.',
  contractThirdPointBottomContent:
    'Haftung: Der Auftragnehmer haftet nicht für Schäden, es sei denn, diese sind auf grobe Fahrlässigkeit oder Vorsatz zurückzuführen.',
  contractFourthPointTitle: 'Beauftragung',
  contractFourthPointContent:
    'Die Beauftragung erfolgt durch den Vertragspartner über die FalschParker App, in besonderen Fällen auch telefonisch.',
  contractFifthPointTitle: 'Sorgfaltspflicht',
  contractFifthPointContent:
    'Der Auftragnehmer erbringt die Leistungen mit der gebotenen Sorgfalt und nach dem neuesten Stand der Technik. Der Auftragnehmer haftet für das Verhalten seiner Mitarbeiter.',
  contractSixthPointTitle: 'Abtretungserklärung (Zession)',
  contractSixthPointContent:
    'Durch Unterzeichnung dieses Vertrages überträgt der Auftraggeber sämtliche Ansprüche gegenüber den Fahrzeughaltern an den Auftragnehmer. Dies betrifft sowohl die Verhängung von Umtriebsentschädigungen als auch das Abschleppen unrechtmässig abgestellter Fahrzeuge.',
  contractSeventhPointTitle: 'Vollmacht',
  contractSeventhPointContent:
    'Der Auftraggeber bevollmächtigt hiermit den Auftragnehmer, in seinem Namen Strafanzeigen wegen Verstößen gegen die Pflicht zur Zahlung für Kraftstoff (Tankflucht) sowie Strafanzeigen wegen Missachtung richterlicher Verbote zu stellen.',
  contractEighthPointTitle: 'Kosten',
  contractEighthPointContent:
    'Die Nutzung der FalschParker App und aller damit verbundenen Dienstleistungen ist für den Vertragspartner kostenlos. Es entstehen weder Kosten von TowShare Group noch Kosten Dritter.',
  contractNinthPointTitle: 'Ort der Vertragsleistungen',
  contractNinthPointContent:
    'Die Dienstleistungen werden auf den zugewiesenen Liegenschaften des Auftraggebers erbracht.',
  contractTenthPointTitle: 'Geheimhaltung',
  contractTenthPointContent:
    'Alle im Rahmen dieser Vereinbarung ausgetauschten Informationen sind vertraulich zu behandeln.',
  contractEleventhPointTitle: 'Beendigung dieser Vereinbarung',
  contractEleventhPointContent:
    'Beide Parteien können diese Vereinbarung jederzeit und ohne Frist beenden, sofern es nicht zur Unzeit erfolgt.',
  contract12PointTitle: 'Inkrafttreten',
  contract12PointContent:
    'Diese Vereinbarung tritt mit Unterzeichnung beider Parteien in Kraft und gilt auf unbestimmte Zeit.',
  contract13PointTitle: 'Vertragsänderung',
  contract13PointContent:
    'Änderungen dieser Vereinbarung bedürfen der schriftlichen Zustimmung beider Parteien.',
  contract14PointTitle: 'Anwendbares Recht',
  contract14PointContent: 'Diese Vereinbarung unterliegt Schweizer Recht.',
  contract15PointTitle: 'Gerichtsstand',
  contract15PointContent:
    'Für Streitigkeiten aus diesem Vertrag sind die Gerichte in Zürich zuständig.',
  schlieren: 'Schlieren',
  zurich: 'Zürich',
  refCodeNotFound: 'Ref Code nicht gefunden',
  notifications: 'Benachrichtigungen',
  contractSignature: 'Auftrag Unterschreiben',
  inputSignature: 'Eingabe Unterschrift',
  signatureInfo:
    'Um den Auftrag abzuschliessen, müssen Sie hier unterschreiben',
  signature: 'Unterschrift',
  termAndConditions: 'Ich bin mit der Zusammenarbeit einverstanden',
  orderNumber: 'Auftragsnummer',
  confirmOrderArrival: 'Bist du sicher, dass du am Ziel angekommen bist?',
  confirmCarPicked:
    'Sind Sie sicher, dass Sie das richtige Auto gefunden haben?',
  confirmCarParkArrival: 'Bist du sicher, dass du angekommen bist?',
  cancelCarParkArrival: 'Möchtest Du den Auftrag wirklich stornieren?',
  uploadVideoAndPhotos: 'Video und Foto hochladen',
  uploadVideoAndPhotosInfo:
    'Um dies zu überprüfen, laden Sie bitte ein Video rund um das Auto hoch, gefolgt von maximal 4 Bildern davon',
  carVideo: 'Video Falschparker',
  carkParkLocation: 'Ablieferungsort Fahrzeug',
  carFalschOffice: 'Car Falsch Office',
  successDriverOrderSubtitle: 'Du hast den Job erfolgreich abgeschlossen!',
  registerPlateCheck: 'Nummernschildkontrolle',
  registerPlateCheckSubtitle:
    'Überprüfen Sie, ob das Auto an der Adresse parken darf',
  parkedCarRegisterPlateCheck: 'Nummernschild überprüfen',
  formPopupValidation:
    'Sind Sie sicher, dass alle Daten, die Sie eingegeben haben, korrekt sind?',
  registerPlate: 'Nummernschild',
  isAllowedToParking: 'ist erlaubt zu parken',
  isNotAllowedToParking: 'darf nicht parken',
  startTicket: 'Start Ticket',
  startTowing: 'Start Abschleppen',
  successLicenseSubtitle: 'Kontrollschild wurde erfolgreich hinzugefügt',
  editLicense: 'Kontrollschild bearbeiten',
  editProperty: 'Editieren Parkplatz / Grundstück',
  changeSave: 'Die Änderungen wurden gespeichert',
  deleteLicense: 'Kontrollschild löschen',
  deleteLicenceConfirm:
    'Sind Sie sicher, dass Sie das hinterlegte Kontrollschild löschen möchten?',
  switchToDriverProfile: 'Wechsel zu Fahrer Profil',
  superAdmin: 'Super Admin',
  user: 'Benutzer',
  driver: 'Abschleppfahrer',
  customerName: 'Benutzer',
  customerNumber: 'Kundennummer',
  orderPlacement: 'Uhrzeit Bestellung',
  ticketOrderPlacement: 'Uhrzeit Parkvergehen',
  orderAcceptence: 'Annahme der Bestellung',
  cancelatioTime: 'Abbruchzeit',
  arrivalTime: 'Ankunftszeit',
  timeOfPickup: 'Uhrzeit der Abholung',
  timeOfExecution: 'Zeitpunkt der Übergabe',
  verifyCarOwner: 'Autobesitzer verifizieren',
  fullName: 'Vollständiger Name',
  fullNamePlaceholder: 'Vollständiger Name eingeben',
  contactDetails: 'Kontakt Details',
  emailAddress: 'E-mail',
  fullAddress: 'Vollständige Adresse',
  driverId: 'Führerausweis & ID (Front)',
  carRegistration: 'Fahrzeugausweis',
  recent: 'Neueste',
  older: 'Ältere',
  licenseFront: 'Führerausweis & ID (Front)',
  licenseBack: 'Führerausweis & ID (Back)',
  govermentIdFront: 'Fahrzeugausweis',
  govermentIdBack: 'Passport',
  helpAndSupport: 'Hilfe und Unterstützung',
  falschParkerHelp: 'Falsh Parker Hilfe',
  helpContent:
    'Den Support erreichen Sie rund um die Uhr telefonisch oder per WhatsApp. Fragen zur Funktionsweise der App oder zu einzelnen Aktionen beantworten wir direkt und ohne Umwege.',
  allowedCarPopup:
    'Dieses Auto darf geparkt werden und kann nicht abgeschleppt werden.',
  back: 'Zurück',
  call: 'Anrufen',
  message: 'Nachricht',
  confirm: 'bestätigen',
  confirmShort: 'JA',
  confirmNext: 'Weiter',
  driverName: 'Fahrername',
  delete: 'löschen',
  retake: 'wiederholen',
  language: 'Sprache',
  defaultLanguage: 'Standardsprache',
  de: 'Deutsch',
  en: 'Englisch',
  it: 'Italiano',
  fr: 'Français',
  oldPasswordPlaceholder: 'Altes Passwort eingeben',
  newPasswordPlaceholder: 'Neues Passwort eingeben',
  destinationArrived: 'Zielort Angekommen',
  userName: 'Nutzername',
  downloadReceipt: 'Protokoll herunterladen',
  imptint: 'Impressum',
  imptintTitle: 'Impressum',
  driverAcceptError:
    'Sie können nicht mehr als einen Bericht gleichzeitig annehmen',
  referAndPromote: 'Hinzufügen',
  referToPromote: 'Fügen Sie einen Mitarbeiter hinzu',
  orderStatus: 'Auftrags Status',
  driverAcceptConfirm:
    'Sind Sie sicher, dass Sie den Auftrag akzeptieren möchten?',
  driverDectineConfirm: 'Möchten Sie den Bericht wirklich ablehnen?',
  backendErrorPartOne:
    'Wir sind auf einen Fehler gestoßen. Bitte wenden Sie sich an den Support von ',
  backendErrorPartTwo: '',
  noNotification: 'Es liegen noch keine Benachrichtigungen vor',
  closeReportContent: 'Möchten Sie den Auftrag wirklich abbrechen?',
  executeOrder: 'Fahrzeugrückgabe starten',
  pleaseAddSignature: 'Bitte Unterschrift hinzufügen',
  next: 'Weiter',
  accept: 'Annehmen',
  decline: 'Abbrechen',
  arrived: 'Angekommen',
  successCarOwenrContractSubtitle:
    'Vielen Dank für den Abschluss des Schleppberichts',
  minute: 'Minute',
  minutes: 'Minuten',
  turnOnGPS: 'GPS einschalten',
  gpsPopupContent:
    'Lieber Nutzer. Bitte schalten Sie das GPS Ihres Geräts ein. Andernfalls können Sie die App nicht verwenden',
  gpsErrorPopupContent:
    'Sehr geehrter Nutzer. Bitte erlauben Sie Ihrem Browser, Ortungsdienste zu verwenden. Sie können dies in den Browsereinstellungen tun',
  404: 'Hoppla! Anscheinend kann die Seite, auf die Sie zugreifen wollten, nicht gefunden werden',
  searchPlaceholder: 'Nummernschild suchen...',
  resetPasswordTitle: 'Email für Passwort wiederherstellen',
  resetPassword: 'Passwort senden',
  resetPasswordError: 'Die angegebene E-Mail ist mit keinem Konto verbunden',
  resetPasswordSuccess:
    'Sie sollten eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts erhalten',
  userExist: 'Diese E-Mail wird für ein anderes Konto verwendet. Bitte anders',
  yes: 'Ja',
  no: 'Nein',
  pinWrongParkedLocation: 'Falschparker Lage',
  pinTowingTruckLocation: 'Abschleppwagen Lage',
  pinParkingSpaceLocation: 'Parkingplatz  Adresse',
  googleMapsPopupContent:
    'Möchtest du Google Maps für die Zielführung verwenden?',
  screenAlwaysOn: 'Bitte lassen Sie den Bildschirm immer eingeschaltet',
  tooBigFile:
    'Die Videodatei ist zu groß. Mach bitte ein neues, kürzeres Video.',
  uploadVideoAndPhotos360: '360 Grad-Videos und  Fotos',
  uploadVideoAndPhotos360Subtitle:
    'Um dies zu überprüfen, laden Sie bitte ein Video des Autos hoch, gefolgt von 3 Bildern davon',
  twentyCashback: '20 CHF CASHBACK',
  fiftyCashback: '50 CHF CASHBACK',
  reportNumber: 'Auftragsnummer',
  tenChf: '10 CHF GUTSCHRIFT',
  referAndGet: 'WEITEREMPFEHLEN + ',
  towingActiveHistoryTitle: 'Neue Aufträge',
  towingDeactiveHistoryTitle: 'Abschleppaufträge',
  ticketHistoryTitle: 'Parkbussen',
  allHistoryTitle: 'Alle Berichtshistorie',
  please: 'Bitte',
  mark: 'bedtätigen',
  rentalAgreementPhotos: 'Mietvertragsfotos',
  addRentalProperty: 'Mietobjekt hinzufügen',
  newRentalPropertyDetails: 'Neue Liegenschaft (Details)',
  addRentalPropertyDetails: 'Mietobjekt hinzufügenen',
  uploadPhotos: 'Fotos hochladen',
  uploadPhotosInfo:
    'Zur Überprüfung laden Sie bitte Bilder des Mietvertrags hoch',
  invalidZipCodePopup:
    'Abschleppservice ist zurzeit nicht in Ihrer Region verfügbar. Wir arbeiten hart daran, bald auch in Ihrer Region verfügbar zu sein',
  paymentMethod: 'Bezahlverfahren',
  invoice: 'Rechnung',
  paymentOnTheSpotCash: 'Bezahlung vor Ort (Kasse)',
  paymentOnTheSpotCard: 'Bezahlung vor Ort (Kreditkarte)',
  freeParking: 'Drop-Off Parkplatz',
  inProgressButton: 'Aktueller Auftrag',
  inProgressSubtitle: 'Überprüfen Sie den laufenden Bericht',
  policyParkingAddress: 'Zielparkplatz Address',
  acceptedReportError:
    'Ein anderer Fahrer hat diesen Bericht bereits akzeptiert',
  clientName: 'Firmenname',
  selectParkingArea: 'Kundenparkplätze in der Umgebung',
  autoGPSOff:
    'Lieber Benutzer. Bitte schalten Sie das GPS auf Ihrem Gerät ein. Andernfalls können Sie die automatische Lokalisierung nicht nutzen.',
  paymentStatus: 'Zahlungsstatus',
  cashbackStatus: 'Cashback Status',
  open: 'Offen',
  paid: 'Bezahlt',
  notPaid: 'Nicht bezahlt ',
  paidOnTheSpot: 'Im Shop bezahlt',
  paidOnSpotButton: 'Kunde bezahlt im Shop',
  pending: 'Ausstehend',
  clients: 'Clients',
  clientNumber: 'Kundennummer',
  contactNumber: 'Kontaktnummer',
  clientFullName: 'Name des Kunden',
  cashbackOn: 'Cashback on',
  cashbackOff: 'Cashback off',
  clientsSearchPlaceholder: 'Kunden suchen ...',
  wrongAddress:
    'Bitte geben Sie die richtige Adresse ein. Ihre Adresse wurde nicht auf Google Maps gefunden',
  clientReports: 'Abgeschlossene Aufträge Kunden',
  clientReportsTitle: 'Abgeschlossene Aufträge des Kunden',
  clientReportsSearchPlaceholder: 'Suchen...',
  downloadContract: 'Zession herunterladen',
  cashback: 'CashBack',
  claimed: 'Beansprucht',
  bankAccount: 'Bankkonto',
  newBank: 'Neus Bankkonto hinzufügen',
  downloadAppPopup: 'Die FalschParker App herunterladen',
  tap: 'Klicken um',
  tapInstruction: 'zum Home-Bildschirm hinzufügen',
  sign: 'Unterzeichnen',
  bill: 'Rechnung',
  gasStationButtonTitle: 'Tankflucht',
  gasStationButtonSubtitle: 'Tanken ohne zu bezahlen',
  photo: 'Foto Kraftfahrzeug',
  video: 'Video (max. 30 sec)',
  controlPlate: 'Kontrollschild',
  controlPlatePlaceholder: 'Gib das Kontrollschild ein',
  billPhoto: 'Foto Tankbeleg',
  gasStationAddressTitle: 'Standort Tankstelle',
  price: 'Preis',
  priceGasStation: 'Tankbetrag',
  billTime: 'Tankzeit',
  gasStationSuccessReport: 'Das Tankvergehen wurde erfolgreich gemeldet',
  bankAccountName: 'Kontoinhaber',
  bankAccountNameePlaceholder: 'Kontoinhaber eingeben',
  gasStationOn: 'Tankflucht on',
  gasStationOff: 'Tankflucht off',
  confirmReportDelete: 'Sind Sie sicher, dass Sie den Auftrag löschen wollen?',
  deleteReport: 'Auftrag löschen',
  gasStationSelect: 'Tankstelle wählen',
  activateParkingTitle:
    'Hinterlegen Sie Ihr Kontrollschild damit wir Sie als Besucher anmelden können',
  activateParkingPlaceholder: 'Kontrollschild eingeben',
  confirmActivateParking: 'Speichern',
  confirmActivatedParking: 'Kontrollschild für 24h hinterlegt',
  cancelSelectParking: 'Stornieren',
  ticketId: 'Verzeigungs-Nr',
  ticketIdPlaceholder: 'QR-Code Scannen',
  notes: 'Notiz',
  notesPlaceholder: 'Optional',
  userOrderAddress: 'Standort auswählen',
  parkingNotes: 'Beschreiben Sie den exakten Standort Ihres Parkplatzes',
  uploadParkingPhotos: 'Machen Sie von Ihrem Parkplatz ein Foto',
  parkingTitle: 'Name Parkplatzstandort',
  reportType: 'Berichtstyp',
  towingTickets: 'Aufträge / Parkbusse',
  priceBill: 'Betrag Tankfüllung',
  priceBillPlaceholder: 'Betrag Tankfüllung einegen',
  priceCarOwner: 'Preis Abschleppauftrag',
  priceCarOwnerPlaceholder: 'Preis Abschleppauftrag einegen',
  plateValidation: 'Nur Buchstaben und Zahlen',
  selectUserType: 'Bitte wählen Sie den Benutzertyp aus:',
  companyClient: 'Geschäftskunde',
  userClient: 'Privatkunde',
  pleaseSelectParking: 'Bitte wählen Sie eine Parkzone',
  pleaseChangeAddress:
    'Bitte geben Sie eine andere Adresse ein und wählen Sie eine Parkzone',
  appVersion: 'App-Version',
  confrimPayOnSpot: 'Bestätigen Sie die Zahlung vor Ort?',
  selectTheLocation: 'Standort auswählen',
  qrScanner: 'QR Scanner',
  fuelFraud: 'Tankflucht',
  ticketSection: 'Parkbusse',
  pricePlaceholder: 'Preis eingeben',
  cashbackPlaceholder: ' Cashback eingeben',
  gasStationSection: 'Tankflucht',
  towingSection: 'Abschleppen',
  accidentalEvasion: 'War im Shop',
  intensionalEvasion: 'War nicht im Shop',
  accidentalEvasionCashback: 'War im Shop Cashback',
  intensionalEvasionCashback: 'War nicht im Shop Cashback',
  emptyRun: 'Leerfahrt',
  cameraAlert:
    'Die Kamera ist blockiert oder nicht zugänglich. Bitte erlauben Sie die Kamera in Ihren Browser-Berechtigungen und laden Sie neu.',
  loadingMediaPopup:
    'Dateien werden hochgeladen, bitte schliessen Sie die App nicht',
  objectionRaiseEmailPlaceholder: 'Ihre E-Mail',
  notePlaceholder: 'Beschreibung Einwand',
  driverCityPlaceholder: 'Ort eingeben',
  qrCodeId: 'QR-Parkbusse ID',
  cancelButton: 'Abbrechen',
  sharingError: 'Ihr System unterstützt die Freigabe dieser Datei nicht',
  startScanner: 'Starten des Scanners',
  downloadQrCode: 'QR Code herunterladen',
  generateQrCode: 'QR Code anzeigen',
  pleaseAddPrice: 'Bitte, Preis hinzufügen',
  emptyRunCashback: 'Leerfahrt Cashback',
};
